import request from '@/utils/request'

//-------------微信模版---------
//新增微信消息模板
export function addWxtpl(data) {
  return request({
    url: '/MA_wxtpl/add',
    method: 'post',
    params: data
  })
}

//删除微信消息模板
export function deleteWxtpl(data) {
  return request({
    url: '/MA_wxtpl/delete',
    method: 'post',
    params: data
  })
}

//修改微信消息模板
export function updateWxtpl(data) {
  return request({
    url: '/MA_wxtpl/update',
    method: 'post',
    params: data
  })
}


//微信模板分页查询
export function queryWxtpl(data) {
  return request({
    url: '/MA_wxtpl/page',
    method: 'post',
    params: data
  })
}


//微信模板详情
export function detailWxtpl(data) {
  return request({
    url: '/MA_wxtpl/detail',
    method: 'post',
    params: data
  })
}
	
  	
//微信模板属性项删除
export function deleteclmWxtpl(data) {
  return request({
    url: '/MA_wxtpl/deleteclm',
    method: 'post',
    params: data
  })
}