<template>
  <div>
    <el-form
      style="width: 90%;"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="140px"
      class="demo-ruleForm"
    >
      <el-form-item label="微信模板id" prop="wxtempid">
        <el-input v-model="ruleForm.wxtempid"></el-input>
      </el-form-item>
      <el-form-item label="标题" prop="title">
        <el-input v-model="ruleForm.title"></el-input>
      </el-form-item>
      <el-form-item label="一级行业" prop="bizlvone">
        <el-input v-model="ruleForm.bizlvone"></el-input>
      </el-form-item>
      <el-form-item label="二级行业" prop="bizlvtwo">
        <el-input v-model="ruleForm.bizlvtwo"></el-input>
      </el-form-item>

      <el-row v-for="(temprop, index) in ruleForm.temprops" :key="temprop.key">
        <el-col :span="8">
          <el-form-item
            label-width="140px"
            :label="'属性名' +  (index+1)"
            :prop="'temprops.' + index + '.attrname'"
          >
            <el-input v-model="temprop.attrname"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item
            label-width="90px"
            :label="'值' +  (index+1)"
            :prop="'temprops.' + index + '.attrvalue'"
            :rules="{
              required: true, message: '值不能为空', trigger: 'blur'
            }"
          >
            <el-input v-model="temprop.attrvalue"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label-width="90px"
            :label="'顺序' + (index+1)"
            :prop="'temprops.' + index + '.sort'"
            :rules="{
              required: true, message: '顺序不能为空', trigger: 'blur'
            }"
          >
            <el-input @change="sortChange(index)" oninput="value=value.replace(/[^\d]/g,'')" v-model="temprop.sort"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label-width="20px">
            <el-button @click.prevent="removeTemprop(temprop)">删除</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item>
          <el-button @click="addTemprop">新增属性</el-button>
        </el-form-item>
      </el-row>
      

      <el-form-item>
        <el-button v-if="editType === 'add'" type="primary" @click="submitForm('ruleForm')"
          >新建模版</el-button
        >
        <el-button v-if="editType === 'update'" type="primary" @click="submitForm('ruleForm')"
          >修改模版</el-button
        >
        <el-button  v-if="editType === 'add'" @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

import { addWxtpl,updateWxtpl,detailWxtpl,deleteclmWxtpl } from "@/api/wxtpl/index.js";
export default {
  name: "",
  components: {},
  props: {
    editType:{
      type: String,
      default: ''
    },
    tempId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      ruleForm: {
        id: null,
        wxtempid: "",
        title: "",
        bizlvone: "",
        bizlvtwo: "",
        temprops: [{
          id: null,
          attrname: '',
          attrvalue:　'',
          sort: null
       }]
      },
      //修改属性按钮
      // savePropsFlag: false,
      rules: {
        wxtempid: [
          { required: true, message: "请输入微信模板id", trigger: "change" },
        ],
        title: [
          { required: true, message: "请输入标题", trigger: "change" },
        ]
      },
      sortArr:[]
    };
  },
  watch: {
    
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            userid: this.GLOBAL.adminId(),
            wxtempid: this.ruleForm.wxtempid,
            title: this.ruleForm.title,
            bizlvone: this.ruleForm.bizlvone,
            bizlvtwo: this.ruleForm.bizlvtwo,
          }
          console.log(this.editType)
          if(this.editType==='add'){
            //新增的时候一起把属性新增了
            let temps = this.ruleForm.temprops;
            let tempsArr = []
            temps.map(item=>{
              tempsArr.push(item.attrname+'-'+item.attrvalue+'-'+item.sort+'-')
            })
            data.details = tempsArr.join(',')
            addWxtpl(data).then((res) => {
              if (res.result == 200) {
                this.$message.success('添加成功');
                this.resetForm('ruleForm')
                this.$emit('initList');
              } else {
                this.$message.error(res.description);
              }
            });
          }else if(this.editType==='update'){
            //修改模版的时候，只能修改模版主体
            data.id = this.ruleForm.id;
            let temps = this.ruleForm.temprops;
            let tempsArr = []
            temps.map(item=>{
              tempsArr.push(item.attrname+'-'+item.attrvalue+'-'+item.sort+'-'+item.id)
            })
            data.details = tempsArr.join(',');
            updateWxtpl(data).then((res) => {
              if (res.result == 200) {
                this.$message.success('修改成功');
                this.resetForm('ruleForm')
                this.$emit('initList');
              } else {
                this.$message.error(res.description);
              }
            });
          }

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //获取模版详情
    getTemDetail(id){
      detailWxtpl({
        userid: this.GLOBAL.adminId(),
        id: id
      }).then((res) => {
        if (res.result == 200) {
          this.ruleForm = {
            id: res.detail.id,
            wxtempid: res.detail.wxtempid,
            title: res.detail.title,
            bizlvone: res.detail.bizlvone,
            bizlvtwo: res.detail.bizlvtwo,
            temprops: res.detail.wxtemplateclms
          }
        } else {
          this.$message.error(res.description);
        }
      });
    },
    //删除属性
    removeTemprop(item) {      
      if(this.ruleForm.temprops.length === 1){
        this.$message.warning('属性信息至少保留一条');
        return false
      }
      if(item.id){
        this.$confirm('该属性已存在，是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteclmWxtpl({
            userid: this.GLOBAL.adminId(),
            id: item.id
          }).then((res) => {
            if (res.result == 200) {
              let index = this.ruleForm.temprops.indexOf(item)
              if (index !== -1) {
                this.ruleForm.temprops.splice(index, 1)
                // this.savePropsFlag = true;
              }
            } else {
              this.$message.error(res.description);
            }
          });
          
        }).catch(() => {
                   
        });
      }else{
        let index = this.ruleForm.temprops.indexOf(item)
        if (index !== -1) {
          this.ruleForm.temprops.splice(index, 1)
          // this.savePropsFlag = true;
        }
      }
    },
    //新增属性
    addTemprop() {
      // this.savePropsFlag = true;
      this.ruleForm.temprops.push({
        id:'',
        attrname: '',
        attrvalue:　'',
        sort: null
      });
    },
    sortChange(index){
      let curSort = this.ruleForm.temprops[index].sort;
      console.log(curSort)
      let temprops = [...this.ruleForm.temprops];
      console.log(temprops)
      temprops.splice(index, 1)
      console.log(temprops)
      temprops.map(item=>{
        if(curSort == item.sort){
          this.ruleForm.temprops[index].sort = null;
          this.$message.warning('顺序的值不能重复，请重新输入')
        }
      })
      // console.log(curSort)
    }

  },
};
</script>

<style lang="scss" scoped>
</style>