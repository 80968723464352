<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="onSearch">刷新</el-button>
          <el-button type="primary" @click="addFn('add')">新增模版</el-button>
        </el-form-item>
      </el-form>
    </div>


    <el-table :data="tableData" stripe>
      <el-table-column prop="wxtempid" label="微信模版id" width="400"></el-table-column>
      <el-table-column prop="title" label="标题"></el-table-column>
      <el-table-column label="一级行业">
        <template slot-scope="scope">
          <span>{{ scope.row.bizlvone?scope.row.bizlvone:'/' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="二级行业">
        <template slot-scope="scope">
          <span>{{ scope.row.bizlvtwo?scope.row.bizlvtwo:'/' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="180px">
        <template slot-scope="scope">
          <span>{{ scope.row.createtime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180px">
        <template slot-scope="scope">
          <el-button type="primary"  @click="editFn('update',scope.row)">修改</el-button>
          <el-button type="danger" @click="deleteFn(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>



    <div class="paginationbox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="fenye.pagesizes"
        :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fenye.totalnum"
      >
      </el-pagination>
    </div>


    <!-- 新增模版 -->
    <el-dialog append-to-body :title="getTitle()" :close-on-click-modal='false' :visible.sync="addDialogVisible" v-if="addDialogVisible" width="1000px">
      <tem-form :editType="editType" :tempId="currentTempId" @initList="initList" ref="temform"></tem-form>
    </el-dialog>

  </div>
</template>

<script>
import { queryWxtpl,deleteWxtpl } from "@/api/wxtpl/index.js";
import temForm from './tem-form'
export default {
  name: "",
  components: {
    temForm
  },
  props: {
    actuuid:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tableData: [],
      fenye: {
        totalnum: 0,
        pagesizes: [10, 20, 30, 40, 50],
        pagesize: 10,
      },
      currentPage: 1,
      editType: '',
      //新增模版字段
      addDialogVisible: false,
      //修改模版字段
      currentTempId: null

    };
  },
  watch: {},
  created() {
  },
  mounted() {
    this.getList();
  },
  computed: {},
  methods: {
    getList() {
      queryWxtpl({
        userid: this.GLOBAL.adminId(),
        pageSize: this.fenye.pagesize,
        pageNum: this.currentPage
      }).then((res) => {
        if (res.result == 200) {
          let detail = res.detail;
          this.fenye.totalnum = detail.totalRow;
          this.tableData = detail.list;
        } else {
          this.$message.error(res.description);
        }
      });

    },
    //切换页面条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    //切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    //搜索
    onSearch(){
      this.currentPage = 1;
      this.getList();
    },
    //新增模版
    addFn(type){
      this.$nextTick(()=>{
        this.editType = type;
      })
      this.addDialogVisible = true;
    },
    //删除模版
    deleteFn(row){
      this.$confirm('是否确认删除该模版?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteWxtpl({
            userid: this.GLOBAL.adminId(),
            id: row.id
          }).then((res) => {
            if (res.result == 200) {
              this.onSearch();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            } else {
              this.$message.error(res.description);
            }
          });
          
        }).catch(() => {
                   
        });
    },
    //返回模版标题
    getTitle(){
      if(this.editType == 'add'){
        return '新增模版'
      }else if(this.editType == 'update'){
        return '修改模版'
      }if(this.editType == 'detail'){
        return '模版详情'
      }
    },
    //编辑模版
    editFn(type, row){
      console.log(row)
      console.log(type)
      this.addDialogVisible = true;
      this.$nextTick(()=>{
        this.editType = type;
        this.currentTempId = row.id;
        this.$refs.temform.getTemDetail(this.currentTempId);
      })
    },
    //刷新列表，关闭弹框
    initList(){
      this.addDialogVisible = false;
      this.onSearch();
    }
  },
};
</script>

<style lang="scss" scoped>
.searchbox{
  margin: 0 10px;
}
.paginationbox {
  text-align: right;
  margin-top: 20px;
}
</style>